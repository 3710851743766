import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\nBASE_URL = "/";\nIS_LOGGEDIN = "0";\nSITE_URL = BASE_URL;\nLANG_BASE_URL = "/";\n'
          }}
        />

        <script
          async
          type="text/javascript"
          src="/static/js/generated_js/958a58972bf272cd5ead1fe8b4a225e1.js?=1588071940"
        />
        <script async typ="text/javascript" src="/static/js/generated_js/jquery-migrate-1.4.1-min.js" />
        <div id="layout">
          <div className="head">
            <div className="inner">
              <a className="logo" href="/">
                <img src="/static/i/layout/New_Logo.png" alt="VISINE" />
              </a>
              <div className="menuHolder">
                <ul id="menu">
                  <li className="mainMenu">
                    <a href="/">Főoldal</a>
                  </li>
                  <li className="mainMenu">
                    <a className="active" href="/voros-szemre/">
                      Vörös szemre
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/termekeink/visine-classic/">
                      VISINE<sup>®</sup> Rapid
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/szemtorna/">Szemtorna</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/kapcsolat/">Kapcsolat</a>
                  </li>
                </ul>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div id="content" className="content">
            <div className="symptomsPage red">
              <div className="inner">
                <h1>Szemirritáció</h1>
                <div className="lead">
                  <img
                    src="/static/i/layout/header_red_eye.jpg"
                    alt="vörös szem"
                    className="headerImage"
                  />
                  <p className="leadText">
                    Sok ember tapasztal enyhe irritációt, vörösséget és
                    szemduzzanatot időnként. Ez a káros környezeti hatásoknak
                    kitett szem gyakori reakciója. A kivörösödött, irritált szem
                    a betegség látszatát keltheti, ami az első benyomást is
                    befolyásolhatja bizonyos helyzetekben. Például egy
                    állásinterjú alkalmával, vagy társasági összejövetelen, ahol
                    szeretnénk jól mutatni. Az enyhe irritáció, szemvörösség és
                    duzzanat nem csupán esztétikai probléma. Ezeket a tüneteket
                    általában erős diszkomfort érzés, valamint a szemek
                    dörzsölésének kényszere kíséri.
                  </p>
                  <div className="clear" />
                </div>
                <div className="mainText">
                  <h2>MI AZ A SZEMIRRITÁCIÓ?</h2>
                  <p>
                    A szem irritációja és kivörösödése gyakran előfordulhat. A
                    szemirritációt okozhatják környezeti tényezők, pl. füst, 
                    por, szél, fény vagy klóros víz. A szem ilyen esetben viszkethet,
                    éghet vagy könnyezhet is..
                  </p>
                  <h2>A SZEMIRRITÁCIÓ OKAI</h2>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_allergy.png"
                            alt="allergia"
                          />
                        </td>
                        <td>
                          Pollen (Ez a probléma a tavaszi-nyári időszakban
                          súlyosbodik a virágzó növények miatt.)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src="/static/i/icons/icon_wind.png" alt="szél" />
                        </td>
                        <td>Szél</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="/static/i/icons/icon_dust.png" alt="por" />
                        </td>
                        <td>Por</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_smoke.png"
                            alt="füst"
                          />
                        </td>
                        <td>Füst</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_chlorine.png"
                            alt="klóros víz - kozmetikumok"
                          />
                        </td>
                        <td>Klóros víz</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_sun.png"
                            alt="Fény"
                          />
                        </td>
                        <td>Fény</td>
                      </tr>
                    </tbody>
                  </table>
                  <h2>A SZEMIRRITÁCIÓ TÜNETEI</h2>
                  <table className="left">
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_red_eye.png"
                            alt="vörös szemek"
                          />
                        </td>
                        <td>Vörös szemek</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_itching.png"
                            alt="viszketés"
                          />
                        </td>
                        <td>Viszketés</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_burning.png"
                            alt="Égető érzés"
                          />
                        </td>
                        <td>Égető érzés</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_water.png"
                            alt="vizenyős szemek"
                          />
                        </td>
                        <td>Vizenyős szemek</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="/static/i/icons/icon_tears.png"
                            alt="intenzív könnyezés"
                          />
                        </td>
                        <td>Intenzív könnyezés</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="productRecommendation">
                    <a
                      className="productImage"
                      href="/termekeink/visine-classic/"
                    >
                      <img
                        src="/static/i/products/Visine-Rapid-left-135x250.png"
                        alt="VISINE Rapid 0,5 mg/ml oldatos szemcsepp"
                      />
                    </a>
                    <div className="symptom">
                      <a href="/termekeink/visine-classic/">
                        Vörös, irritált szemektől szenved?
                      </a>
                    </div>
                    <a
                      href="/termekeink/visine-classic/"
                      className="tryProduct classic"
                    />
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="footer">
            <div className="inner">
              <div className="productStatus">
                <div className="contentInner">
                  <div className="footerInner">
                    VISINE<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp. Vény
                    nélkül kapható, tetrizolin-hidroklorid hatóanyagú gyógyszer.
                  </div>
                </div>
              </div>
              <div className="footerMenu">
                <ul>
                  <li>
                    <a href="/jogi-nyilatkozat/">Jogi nyilatkozat</a>
                  </li>
                  <li>
                    <a href="/adatvedelmi-szabalyzat/">
                      Adatvédelmi szabályzat
                    </a>
                  </li>
                  <li>
                    <a href="/oldalterkep/">Oldaltérkép</a>
                  </li>
                  <li>
                    <a href="/cookie-szabalyzat/">Cookie szabályzat</a>
                  </li>
                  <li>
                    <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</a>
                  </li>
                </ul>
              </div>
              <div className="johnson">
                <div className="contentInner">
                  <div className="footerInner">
                    <p className="bigger">©JNTL CONSUMER HEALTH (HUNGARY) KFT., 2023. 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em.</p>
                    <p>
                      Ezt az oldalt a JNTL Consumer Health (Hungary) Kft. tette közzé, és
                      felel annak tartalmáért.
                    </p>
                    <p>
                      Ez az oldal magyarországi felhasználók számára készült.
                    </p>
                    <p>Utoljára frissítve: 2023.02.01.</p>
                    <p>HU-VI-2200008</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="risks">
            <div className="contentInner">
              <div className="footerInner">
                A kockázatokról és a mellékhatásokról olvassa el a
                betegtájékoztatót, vagy kérdezze meg kezelőorvosát,
                gyógyszerészét!
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default Page;
